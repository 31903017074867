import React from "react"

import FormInput from "../../Elements/Form/FormInput"
import FormSelect from "../../Elements/Form/FormSelect"
import Section from "../../Elements/Section"

import {
  prescribedMedicineChoices,
} from "../utils/formData"
import {
  handleLoadHospitalOptions,
  handleOnHospitalChange,
} from "../services/handleSelectHospitals"

import hospitals from "../utils/hospitals.json"

const PrescriptionInformation = ({ values }) => {
  const prescriptions = prescribedMedicineChoices.sort((a, b) =>
    a.value.localeCompare(b.value)
  )

  return (
    <Section title="Prescription Information" id="prescription-information">
      <FormSelect
        name="hospitalName"
        options={hospitals}
        value={values?.hospitalName}
        label="Hospital/Clinic"
        helper="Please select 'Other' if you can't find your hospital/clinic in the list."
        placeholder="Jose delos Santos Medical Center"
        loadOptions={handleLoadHospitalOptions}
        onChange={handleOnHospitalChange("hospitalName")}
        defaultOptions
        isRequired
        isAsync
      />
      {values?.hospitalName?.value === "Other" && (
        <FormInput
          name="otherHospital"
          label="Please indicate your hospital/clinic"
          placeholder="Philippine General Hospital"
          isRequired
        />
      )}
      <FormInput
        name="doctorName"
        label="Prescribing Doctor"
        placeholder="Juana dela Cruz"
        isRequired
      />
      <FormSelect
        options={prescriptions}
        name="prescribedMedicine"
        value={values.prescribedMedicine}
        label="Prescribed Medicine"
        isRequired
        placeholder="Select medicine"
      />
    </Section>
  )
}

export default PrescriptionInformation
