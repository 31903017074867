import React from "react"

import Section from "../../Elements/Section"
import formFields from "../utils/patientEnrollmentDynamicFields.json"
import { generateFormField } from "../../Elements/Form/services/form"

const FinancialInformation = ({ values }) => {
  const financialInformationFields = formFields.map((formField) => {
    if (!formField?.referenceAnswer)
      return generateFormField({ formField, values, formFields })
  })

  return (
    <Section title="Financial Information" id="financial-information">
      {financialInformationFields}
    </Section>
  )
}

export default FinancialInformation
