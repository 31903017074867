import { useState } from "react"

import { isBrowser } from "../general"

export const useSession = (formName) => { 
  const [ sessionFormValues, setSessionFormValues ] = useState({})
  const isFormValuesEmpty = Object.keys(sessionFormValues).length === 0 ? true : false

  const initializeFormValues = () => { 
    if (isBrowser()) {
      const storedValues = sessionStorage.getItem(`${formName}FormValues`)
      if (storedValues) { 
        setSessionFormValues(JSON.parse(storedValues))
      }
    }
  }

  const handleFormChange = (values) => {
    if (isBrowser()) {
      sessionStorage.setItem(`${formName}FormValues`, JSON.stringify(values));
    }
  }

  const clearFormValues = () => {
    if (isBrowser()) {
      sessionStorage.removeItem(`${formName}FormValues`);
    }
  }

  return { sessionFormValues, isFormValuesEmpty, initializeFormValues, handleFormChange, clearFormValues }
}